const LockIconClosed = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    class="size-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.5 10.5V7.5a4.5 4.5 0 00-9 0v3"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 10.5h12a2.25 2.25 0 012.25 2.25v6a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18.75v-6A2.25 2.25 0 016 10.5z"
    />
  </svg>
);

const LockIconOpen = () => (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>
);

export { LockIconClosed, LockIconOpen };
